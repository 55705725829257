<template>
  <section>
    <CContainer>
      <CForm @submit.stop.prevent="Save()">
        <CCard>
          <CCardHeader class="d-flex align-items-center justify-content-between">
            <h5 class="mb-0">{{ Action === 'Edit' ? $t('Navigation.Video/Edit') : $t('Navigation.Video/Add') }}</h5>
            <div class="card-header-actions d-flex">
              <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
              <CButton v-if="!$store.state.user.permission.MutipleLocale" color="success" type="submit">
                {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
              </CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <CTabs fade addTabsWrapperClasses="tab-info">
              <CTab :title="$t('Video/Detail.Info')" active>
                <CRow>
                  <CCol md="8">
                    <CInput
                        :label="$t('Video/Detail.Slug')"
                        v-model="Data.Slug"
                        horizontal
                    />
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Video/Detail.Categories') }}
                      </CCol>
                      <CCol sm="9">
                        <multiselect
                          :key="NowChooseLanguage"
                          v-model="SelectCategories"
                          :options="CategoryList"
                          :optionHeight="34"
                          label="label"
                          track-by="value"
                          :placeholder="$t('Global.PleaseSelect')"
                          :selectLabel="$t('Global.SelectLabel')"
                          :selectedLabel="$t('Global.SelectedLabel')"
                          :deselectLabel="$t('Global.DeselectLabel')"
                          :multiple="true"
                          @input="SynchronizeCategories()"
                        >
                          <template #noOptions>
                            {{ $t('Global.NoOptions') }}
                          </template>
                        </multiselect>
                      </CCol>
                    </CRow>
                    <CInput
                      type="text"
                      :label="$t('Video/Detail.Attachment')"
                      v-model="Data.Attachment"
                      horizontal
                    >
                      <template #append>
                        <CButton type="button" color="dark" @click="OpenMediaStore('Attachment', false)">{{ $t('Global.Browse') }}</CButton>
                      </template>
                    </CInput>
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                        :label="$t('Video/Detail.Name')"
                        v-model="Data.Name"
                        horizontal
                      />
                      <CInput
                        :label="$t('Video/Detail.OtherName')"
                        v-model="Data.OtherName"
                        horizontal
                      />
                      <CInput
                        :label="$t('Video/Detail.HeaderText')"
                        v-model="Data.HeaderText"
                        horizontal
                      />
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Video/Detail.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                            :key="NowChooseLanguage"
                            v-model="Data.Tags"
                            :tag-placeholder="$t('Global.EnterToAdd')"
                            :placeholder="$t('Global.PleaseKeyIn')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :options="Data.Tags"
                            :multiple="true"
                            :taggable="true"
                            @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Video/Detail.Actors') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                            :key="NowChooseLanguage + '_actor'"
                            v-model="Data.Variable.Actors"
                            :tag-placeholder="$t('Global.EnterToAdd')"
                            :placeholder="$t('Global.PleaseKeyIn')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :options="Data.Variable.Actors"
                            :multiple="true"
                            :taggable="true"
                            @tag="AddActors">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CTextarea :label="$t('Video/Detail.Introduction')" v-model="Data.Introduction" rows="2" horizontal/>
                    </template>
                    <template v-else>
                      <CInput
                        :label="$t('Video/Detail.Name')"
                        v-model="Data.LanguageData[NowChooseLanguage].Name"
                        horizontal
                      />
                      <CInput
                        :label="$t('Video/Detail.OtherName')"
                        v-model="Data.LanguageData[NowChooseLanguage].OtherName"
                        horizontal
                      />
                      <CInput
                        :label="$t('Video/Detail.HeaderText')"
                        v-model="Data.LanguageData[NowChooseLanguage].HeaderText"
                        horizontal
                      />
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Video/Detail.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                            :key="NowChooseLanguage"
                            v-model="Data.LanguageData[NowChooseLanguage].Tags"
                            :tag-placeholder="$t('Global.EnterToAdd')"
                            :placeholder="$t('Global.PleaseKeyIn')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :options="Data.LanguageData[NowChooseLanguage].Tags"
                            :multiple="true"
                            :taggable="true"
                            @remove="RemoveLocaleTag"
                            @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Video/Detail.Actors') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                            :key="NowChooseLanguage + '_actor'"
                            v-model="Data.LanguageData[NowChooseLanguage].Variable.Actors"
                            :tag-placeholder="$t('Global.EnterToAdd')"
                            :placeholder="$t('Global.PleaseKeyIn')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :options="Data.LanguageData[NowChooseLanguage].Variable.Actors"
                            :multiple="true"
                            :taggable="true"
                            @tag="AddActors">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CTextarea :label="$t('Video/Detail.Introduction')" v-model="Data.LanguageData[NowChooseLanguage].Introduction" rows="2" horizontal/>
                    </template>
                  </CCol>
                  <CCol md="4">
                    <CInput
                        :label="$t('Video/Detail.Sort')"
                        v-model="Data.Sort"
                        horizontal
                    />
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Video/Detail.Status') }}
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="PostStatus"/>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Video/Detail.Preview')">
                <h5>{{ $t('Video/Detail.VideoPreview') }}</h5>
                <hr>
                <CMedia v-if="Data.Variable.Preview.UUID" :asideImageProps="{src: Data.Variable.Preview.Cover}">
                  <h5 class="mt-0">{{ Data.Variable.Preview.Name }}</h5>
                  <p>
                    建立時間: {{ Data.Variable.Preview.CreateTime }}<br>
                    影片網址：{{ Data.Variable.Preview.URI }}<br>
                    影片長度：{{ parseInt(Data.Variable.Preview.Duration / 60)  }}分{{ parseInt(Data.Variable.Preview.Duration % 60) }}秒<br>
                  </p>
                </CMedia>
                <div class="mb-4">
                  <CButton :block="!Data.Variable.Preview.UUID" color="light" type="button" class="mr-2" :class="{'py-3': !Data.Variable.Preview.UUID}" @click="OpenVideoStore('Preview')">
                    <CIcon v-if="!Data.Variable.Preview.UUID" size="xl" name="cil-video" class="d-block mx-auto" />
                    {{ !Data.Variable.Preview.UUID ? $t('Video/Detail.ChooseVideo') : $t('Video/Detail.ReChooseVideo') }}
                  </CButton>
                  <CButton v-if="Data.Variable.Preview.UUID" color="danger" type="button" @click="ResetPreview()">
                    <CIcon size="sm" name="cil-video" class="mr-1 my-0" />
                    {{ $t('Video/Detail.Reset') }}
                  </CButton>
                </div>
                <h5>
                  {{ $t('Video/Detail.Carousel') }}
                </h5>
                <hr>
                <Draggable
                  v-model="Data.Carousel"
                  class="row no-gutters mx-n1 mb-3"
                  tag="div"
                  v-bind="DragOptions"
                  @start="Drag = true"
                  @end="Drag = false"
                >
                  <CCol v-for="(Data, Index) in Data.Carousel" :key="Index" xl="2" lg="3" md="4" sm="6"
                        class="px-1 mb-2">
                    <div class="position-relative CarouselItem">
                      <img v-lazy="Data.URL" class="img-fluid"/>
                      <div class="CarouselTools">
                        <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Carousel', false, Index)">
                          <CIcon name="cil-pencil"/>
                        </CButton>
                        <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Carousel', Index)">
                          <CIcon name="cil-pencil"/>
                        </CButton>
                        <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('Carousel', Index)">
                          <CIcon name="cil-trash"/>
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                  <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                    <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                      <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages"/>
                      <div class="CarouselTools">
                        <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Carousel')">
                          <CIcon name="cil-image-plus"/>
                          {{ $t('Global.Add') }}
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                </Draggable>
                <h5>{{ $t('Video/Detail.Cover') }}</h5>
                <hr>
                <CRow class="no-gutters mx-n1 mb-3">
                  <CCol v-if="Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                    <div class="position-relative CarouselItem">
                      <img v-lazy="Data.Cover" class="img-fluid"/>
                      <div class="CarouselTools">
                        <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                          <CIcon name="cil-pencil"/>
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                  <CCol v-if="!Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                    <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                      <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages"/>
                      <div class="CarouselTools">
                        <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                          <CIcon name="cil-image-plus"/>
                          {{ $t('Global.Add') }}
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                </CRow>
                <h5>{{ $t('Video/Detail.PreviewContent') }}</h5>
                <hr>
                <Editor v-if="NowChooseLanguage === 'default'" api-key="x3zt0pfu7uw915eab118wco1xsrza3rhkysn57vzbfmivqxo" :init="TinyMCE" v-model="Data.Content" />
                <Editor v-else api-key="x3zt0pfu7uw915eab118wco1xsrza3rhkysn57vzbfmivqxo" :init="TinyMCE" v-model="Data.LanguageData[NowChooseLanguage].Content" />
              </CTab>
              <CTab :title="$t('Video/Detail.SEO')">
                <CRow>
                  <CCol md="8">
                    <CInput
                        :label="$t('Video/Detail.SEO/Keyword')"
                        v-model="Data.SEO.Keywords"
                        horizontal
                    />
                    <CInput
                        :label="$t('Video/Detail.SEO/Description')"
                        v-model="Data.SEO.Description"
                        horizontal
                    />
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Video/Detail.PaidContent')">
                <CRow>
                  <CCol md="8" lg="9" xl="10">
                    <template v-if="Data.Variable.ChapterEnable">
                      <CAlert color="secondary" :fade="false">
                        {{ $t('Video/Detail.ChapterEnableInfo') }}
                      </CAlert>
                      <Draggable v-model="PaidContent.ChapterList" tag="div" v-bind="DragOptions"
                                 @start="Drag = true" @end="Drag = false">
                        <CCard v-for="(ChapterData, Index) in PaidContent.ChapterList" class="mb-2"
                               :key="Index">
                          <CButton block color="link" class="text-left text-dark shadow-none card-header"
                                   @click="Accordion = Accordion === Index ? false : Index">
                            <h5 class="m-0">{{ ChapterData.Name }}</h5>
                          </CButton>
                          <CCollapse :show="Accordion === Index">
                            <CCardBody>
                              <h5>{{ $t('Video/Detail.ChapterName') }}</h5>
                              <hr>
                              <CInput v-model="ChapterData.Name" class="mb-4"/>
                              <h5>{{ $t('Video/Detail.ChapterPreview') }}</h5>
                              <hr>
                              <CMedia v-if="ChapterData.Preview.UUID" :asideImageProps="{src: ChapterData.Preview.Cover}">
                                <h5 class="mt-0">{{ ChapterData.Preview.Name }}</h5>
                                <p>
                                  建立時間: {{ ChapterData.Preview.CreateTime }}<br>
                                  影片網址：{{ ChapterData.Preview.URI }}<br>
                                  影片長度：{{ parseInt(ChapterData.Preview.Duration / 60) }}分{{ parseInt(ChapterData.VideoData.Duration % 60) }}秒<br>
                                </p>
                              </CMedia>
                              <CButton :block="!ChapterData.Preview.UUID" color="light" type="button" class="mb-4" :class="{'py-3': !ChapterData.Preview.UUID}" @click="OpenVideoStore('ChapterPreview', Index)">
                                <CIcon v-if="!ChapterData.Preview.UUID" size="xl" name="cil-video" class="d-block mx-auto"></CIcon>
                                {{ !ChapterData.Preview.UUID ? $t('Video/Detail.ChooseVideo') : $t('Video/Detail.ReChooseVideo') }}
                              </CButton>
                              <h5>{{ $t('Video/Detail.ChapterIntroduction') }}</h5>
                              <hr>
                              <CTextarea v-model="ChapterData.Introduction" rows="3" class="mb-4"/>
                              <h5>{{ $t('Video/Detail.ChapterContent') }}</h5>
                              <hr>
                              <Editor api-key="x3zt0pfu7uw915eab118wco1xsrza3rhkysn57vzbfmivqxo" :init="TinyMCE" v-model="ChapterData.Content" />
                              <h5 class="mt-4">{{ $t('Video/Detail.ChapterVideo') }}</h5>
                              <hr>
                              <CMedia v-if="ChapterData.VideoData.UUID" :asideImageProps="{src: ChapterData.VideoData.Cover}">
                                <h5 class="mt-0">{{ ChapterData.VideoData.Name }}</h5>
                                <p>
                                  建立時間: {{ ChapterData.VideoData.CreateTime }}<br>
                                  影片網址：{{ ChapterData.VideoData.URI }}<br>
                                  影片長度：{{ parseInt(ChapterData.VideoData.Duration / 60) }}分{{ parseInt(ChapterData.VideoData.Duration % 60) }}秒<br>
                                </p>
                              </CMedia>
                              <CButton :block="!ChapterData.VideoData.UUID" color="light" type="button" class="mb-4" :class="{'py-3': !ChapterData.VideoData.UUID}" @click="OpenVideoStore('ChapterList', Index)">
                                <CIcon v-if="!ChapterData.VideoData.UUID" size="xl" name="cil-video" class="d-block mx-auto"></CIcon>
                                {{ !ChapterData.VideoData.UUID ? $t('Video/Detail.ChooseVideo') : $t('Video/Detail.ReChooseVideo') }}
                              </CButton>
                            </CCardBody>
                            <CCardFooter class="text-right">
                              <CButton color="danger" type="button" @click="RemoveChapter(Index)">
                                {{ $t('Video/Detail.RemoveChapter') }}
                              </CButton>
                            </CCardFooter>
                          </CCollapse>
                        </CCard>
                      </Draggable>
                    </template>
                    <template v-else>
                      <div>
                        <h5>{{ $t('Video/Detail.Video') }}</h5>
                        <hr>
                        <CMedia v-if="PaidContent.MainContent.VideoData.UUID" :asideImageProps="{src: PaidContent.MainContent.VideoData.Cover}">
                          <h5 class="mt-0">{{ PaidContent.MainContent.VideoData.Name }}</h5>
                          <p>
                            建立時間: {{ PaidContent.MainContent.VideoData.CreateTime }}<br>
                            影片網址：{{ PaidContent.MainContent.VideoData.URI }}<br>
                            影片長度：{{ parseInt(PaidContent.MainContent.VideoData.Duration / 60) }}分{{ parseInt(PaidContent.MainContent.VideoData.Duration % 60) }}秒<br>
                          </p>
                        </CMedia>
                        <div class="mb-4">
                          <CButton :block="!PaidContent.MainContent.VideoData.UUID" color="light" type="button" class="mr-2" :class="{'py-3': !PaidContent.MainContent.VideoData.UUID}" @click="OpenVideoStore('MainContent')">
                            <CIcon v-if="!PaidContent.MainContent.VideoData.UUID" size="xl" name="cil-video" class="d-block mx-auto"></CIcon>
                            {{ !PaidContent.MainContent.VideoData.UUID ? $t('Video/Detail.ChooseVideo') : $t('Video/Detail.ReChooseVideo') }}
                          </CButton>
                          <CButton v-if="PaidContent.MainContent.VideoData.UUID" color="danger" type="button" @click="ResetMainContent()">
                            <CIcon size="sm" name="cil-video" />
                            {{ $t('Video/Detail.Reset') }}
                          </CButton>
                        </div>

                      </div>
                    </template>
                  </CCol>
                  <CCol md="4" lg="3" xl="2">
                    <CRow form class="d-none form-group">
                      <CCol tag="label" sm="6" class="col-form-label">
                        {{ $t('Video/Detail.ChapterEnable') }}
                      </CCol>
                      <CCol sm="6">
                        <CSwitch color="success" :checked.sync="Data.Variable.ChapterEnable"/>
                      </CCol>
                    </CRow>
                    <CButton v-if="Data.Variable.ChapterEnable" block color="success" type="button" @click="AddChapter">
                      {{ $t('Video/Detail.AddChapter') }}
                    </CButton>
                  </CCol>
                </CRow>
              </CTab>
            </CTabs>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton color="success" type="submit">
              {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
            </CButton>
          </CCardFooter>
        </CCard>
        <CElementCover v-if="(Loading === true)" :opacity="0.75">
          <CSpinner color="success"/>
        </CElementCover>
      </CForm>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" ref="MediaStore" @OK="SetImages(ChooseImageType)"></MediaStore>
    <VideoStore :Config="VideoStoreConfig" @OK="SetVideo(SelectType)"></VideoStore>
    <MediaMeta :Config="MediaMetaConfig" @OK="SetMeta"></MediaMeta>
    <input id="tinymce-image-input" type="text" class="d-none">
    <component :is="ProductListModelComponent" :Toggle="ProductListModel" @ChooseProduct="ChooseProduct"/>
  </section>
</template>

<route>
{
"meta": {
"label": "影音內容編輯"
}
}
</route>

<script>
import DataSchema from '@/schema/post/detail'
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'EditVideoContent',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
    WidgetsLocales: () => import('@/components/widgets/WidgetsLocales'),
    MediaStore: () => import('@/components/public/Media'),
    VideoStore: () => import('@/components/public/Video'),
    MediaMeta: () => import('@/components/public/MediaMeta'),
    Draggable: () => import('vuedraggable'),
    Editor
  },
  data() {
    return {
      Action: 'Edit',
      Data: {}, //主資料
      PaidContent: {
        ChapterList: [],
        MainContent: {
          Name: 'Default Chapter Name',
          Introduction: '',
          Content: '',
          VideoData: {
            MediaTitle: '',
            URL: '',
            Duration: '',
            Cover: this.$store.state.notFoundImage
          }
        }
      },
      PermissionPassword: '',

      Accordion: 0,
      SelectType: '',
      OldSlug: '',
      SelectCategories: [], //格式化後的已選商品分類
      PostStatus: false, //格式化後的狀態開關
      EnableResponseContent: false, //格式化後的響應式商品內容開關
      Debug: false,
      CategoryList: [],
      ProductListModel: false,
      ProductListModelComponent: null,
      TinyMCE: {
        ...TinyMCESetting,
        file_picker_callback: (callback) => {
          this.OpenMediaStore('Editor', false)
          const input = document.getElementById('tinymce-image-input')
          input.addEventListener('change', (event) => {
            callback(event.target.value, {title: ''})
          })
        },
      },

      NowChooseDevice: 'Web',
      NowChooseLanguage: 'default',
      Loading: false,
      Drag: false,
      ChooseImageType: '',
      ChooseImageIndex: false,
      ChooseVideoIndex: false,

      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
      VideoStoreConfig: {
        Display: false,
        ChooseVideo: {},
      },
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {}
      },

      AccessPrivateOption: [
        {value: 'Public', label: this.$t('Video/Detail.AccessPrivateType.Public')},
        {value: 'Private', label: this.$t('Video/Detail.AccessPrivateType.Private')},
        {value: 'Condition', label: this.$t('Video/Detail.AccessPrivateType.Condition')}
      ],
      AccessPrivateCondition: [
        {value: 'Purchase', label: this.$t('Video/Detail.AccessPrivateCondition.Purchase')}
        // {value: 'SpecifyMemberGroup', label: this.$t('Video/Detail.AccessPrivateCondition.SpecifyMemberGroup')},
        // {value: 'SpecifyMember', label: this.$t('Video/Detail.AccessPrivateCondition.SpecifyMember')},
        // {value: 'SpecifyMemberTag', label: this.$t('Video/Detail.AccessPrivateCondition.SpecifyMemberTag')}
      ]
    }
  },
  computed: {
    DragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    ProductField () {
      return [
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
        { key: 'CustomSeriesNum', label: this.$t('Product/List.CustomSeriesNum') },
        { key: 'SeriesNum', label: this.$t('Product/List.SeriesNum') },
        { key: 'Status', label: this.$t('Product/List.Status') },
        { key: 'Action', label: '' }
      ]
    }
  },
  created() {
    Object.assign(this.$data.Data, DataSchema)
    this.$set(this.$data, 'Data', {
      ...DataSchema,
      Taxonomy: 'Video',
      Variable: {
        // 章節設定
        ChapterEnable: false,
        Preview: {
          MediaTitle: '',
          URL: '',
          Duration: '',
          Cover: this.$store.state.notFoundImage
        },
        Actors: []
      }
    })
    this.Action = (this.$route.name === 'video-detail-add' ? 'Add' : 'Edit')
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      const Functions = [this.GetCategories()]
      if (this.Action === 'Edit') Functions.push(this.GetData())
      return Promise.all(Functions).then(() => {
        //詳細資料處理
        if (this.Action === 'Edit') {
          this.HandleDetailData()
        }
        //多語系資料處理
        if (this.$store.state.user.permission.MutipleLocale && this.Action === 'Add') {
          this.$set(this.Data, 'LanguageData', {})
          this.$store.state.user.permission.Locales.forEach((locale) => {
            this.$set(this.Data.LanguageData, locale, {
              Name: '',
              OtherName: '',
              Introduction: '',
              HeaderText: '',
              Content: '',
              Attachment: '',
              Tags: [],
              SEO: {
                Description: '',
                Keywords: ''
              },
              Variable: {
                Actors: [],
              },
              ResponseData: {
                Mobile: {
                  Content: ''
                },
                Web: {
                  Content: ''
                }
              }
            })
          })
        }
        this.NowChooseLanguage = this.$store.state.user.permission.MutipleLocale ? this.$store.state.user.permission.DefaultLocale : 'default'
        return true
      }).catch((err) => {
        throw err
      })
    },
    Save() {
      let Action
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Video/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        this.SetPassword((this.Action === 'Edit' ? this.Data._id : result.data._id))
        if (this.Action === 'Add') {
          this.$router.replace('/video/detail/' + result.data.Slug)
        }
        if (this.Action === 'Edit' && this.OldSlug !== this.Data.Slug) {
          this.$router.replace('/video/detail/' + this.Data.Slug)
        }
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code + ']',
          text: this.$t('Message.Video/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.Data.Slug === '') {
        throw {
          code: 403,
          msg: this.$t('Message.Video/SlugEmpty')
        }
      }
      this.Data.Variable.LengthTime = {
        M: parseInt(this.PaidContent.MainContent.VideoData.Duration / 60),
        S: parseInt(this.PaidContent.MainContent.VideoData.Duration % 60)
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/video/add',
        method: 'post',
        data: {
          ...this.Data,
          Status: this.PostStatus ? 1 : 0,
          EnableResponseContent: this.EnableResponseContent ? 1 : 0,
          ...this.PaidContent
        }
      }).then(({data}) => {
        this.Data._id = data._id
        return {
          ...data,
          msg: this.$t('Message.Video/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      if (this.Data.Slug === '') {
        throw {
          code: 403,
          msg: this.$t('Message.Video/SlugEmpty')
        }
      }
      this.Data.Variable.LengthTime = {
        M: parseInt(this.PaidContent.MainContent.VideoData.Duration / 60),
        S: parseInt(this.PaidContent.MainContent.VideoData.Duration % 60)
      }
      const updateData = {
        ...this.Data,
        Status: this.PostStatus ? 1 : 0,
        EnableResponseContent: this.EnableResponseContent ? 1 : 0
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/video/edit',
        method: 'post',
        data: {
          id: this.Data._id,
          slug: this.OldSlug,
          updateData,
          ...this.PaidContent
        }
      }).then(() => {
        return {
          msg: this.$t('Message.Video/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    SetPassword(_contentId) {
      if (this.PermissionPassword !== '') {
        return this.$store.dispatch('InnerRequest', {
          url: '/video/detail/setpassword',
          method: 'post',
          data: {
            contentID: _contentId,
            password: this.PermissionPassword
          }
        }).then(() => {
          return true
        }).catch((err) => {
          this.Loading = false
          throw err
        })
      }
    },
    GetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/video/detail',
        method: 'get',
        params: {
          slug: this.$route.params.slug
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.detail
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetCategories() {
      return this.$store.dispatch('InnerRequest', {
        url: '/video/category/list',
        method: 'get',
      }).then(({data}) => {
        this.CategoryList = data.list.map((category) => {
          return {label: category.Name, value: category.Slug}
        })
      }).catch((err) => {
        throw err
      })
    },
    HandleDetailData() {
      this.SelectCategories = this.CategoryList.filter((data) => {
        return this.Data.Categories.includes(data.value)
      })

      if (!this.Data.Variable.Actors) {
        this.$set(this.Data.Variable, 'Actors', [])
      }

      this.OldSlug = this.Data.Slug
      this.PostStatus = (this.Data.Status === 1)
      this.EnableResponseContent = (this.Data.EnableResponseContent === 1)

      //多語系資料處理
      if (this.$store.state.user.permission.MutipleLocale) {
        if (!this.Data.LanguageData) {
          this.$set(this.Data, 'LanguageData', {})
        }
        this.$store.state.user.permission.Locales.forEach((locale) => {
          if (!this.Data.LanguageData[locale]) {
            this.$set(this.Data.LanguageData, locale, {
              Name: '',
              OtherName: '',
              Introduction: '',
              HeaderText: '',
              Content: '',
              Attachment: '',
              Tags: [],
              SEO: {
                Description: '',
                Keywords: ''
              },
              Variable: {
                Actors: [],
              },
              ResponseData: {
                Mobile: {
                  Content: ''
                },
                Web: {
                  Content: ''
                }
              }
            })
          }
        })
        this.NowChooseLanguage = this.$store.state.user.permission.DefaultLocale || 'default'
      }

      if (this.Action === 'Edit') {
        return this.$store.dispatch('InnerRequest', {
          url: '/video/paidContent',
          method: 'get',
          params: {
            id: this.Data._id
          }
        }).then(({ data }) => {
          this.PaidContent = data.Data
        }).catch((err) => {
          throw err
        })
      }
    },
    SynchronizeDefaultLocaleData() {
      const DefaultLocale = this.$store.state.user.permission.DefaultLocale
      if (DefaultLocale !== 'default') {
        this.Data.Name = this.Data.LanguageData[DefaultLocale].Name
        this.Data.OtherName = this.Data.LanguageData[DefaultLocale].OtherName
        this.Data.Introduction = this.Data.LanguageData[DefaultLocale].Introduction
        this.Data.HeaderText = this.Data.LanguageData[DefaultLocale].HeaderText
        this.Data.Content = this.Data.LanguageData[DefaultLocale].Content
        this.Data.Attachment = this.Data.LanguageData[DefaultLocale].Attachment
        this.Data.SEO = this.Data.LanguageData[DefaultLocale].SEO
        this.Data.ResponseData = this.Data.LanguageData[DefaultLocale].ResponseData
        this.Data.Variable.Actors = this.Data.LanguageData[DefaultLocale].Variable.Actors
      }
    },
    SynchronizeCategories() {
      this.Data.Categories = this.SelectCategories.map(data => {
        return data.value
      })
    },
    AddTag (newTag) {
      this.Data.Tags.push(newTag)
      if (this.NowChooseLanguage !== 'default') {
        this.Data.LanguageData[this.NowChooseLanguage].Tags.push(newTag)
      }
    },
    RemoveLocaleTag (removedOption) {
      this.Data.Tags.splice(this.Data.Tags.indexOf(removedOption), 1)
    },
    AddActors(newTag) {
      if (this.NowChooseLanguage !== 'default') {
        this.Data.LanguageData[this.NowChooseLanguage].Variable.Actors.push(newTag)
      } else {
        this.Data.Variable.Actors.push(newTag)
      }
    },
    AddChapter() {
      if (!this.PaidContent.ChapterList) {
        this.$set(this.PaidContent, 'ChapterList', [])
      }
      this.PaidContent.ChapterList.push({
        Name: 'Default Chapter Name',
        Preview: {
          MediaTitle: '',
          URL: '',
          Duration: '',
          Cover: this.$store.state.notFoundImage
        },
        Introduction: '',
        Content: '',
        VideoData: {
          MediaTitle: '',
          URL: '',
          Duration: '',
          Cover: this.$store.state.notFoundImage
        }
      })
    },
    RemoveChapter(index) {
      this.PaidContent.ChapterList.splice(index, 1)
    },
    OpenMediaStore(type = 'Carousel', multiple = true, index = false) {
      this.ChooseImageType = type
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    async SetImages(type = 'Carousel') {
      const storageInstance = this.$store.state.userProjectApp.storage()
      switch (type) {
        case 'Cover':
        case 'Attachment':
          this.SetSingleImage(type)
          break
        case 'Editor':
          document.getElementById('tinymce-image-input').value = await this.ResolveImageURL()
          document.getElementById('tinymce-image-input').dispatchEvent(new Event('change'));
          break
        default:
          if (typeof this.Data[type] === 'undefined') {
            this.$set(this.Data, type, [])
          }
          this.ChooseImageType = ''
          switch (typeof this.MediaStoreConfig.ChooseImageList) {
            case 'string':
              if (this.$store.state.user.permission.StaticDomain) {
                this.$set(this.Data[type], this.ChooseImageIndex, {
                  URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                  Alt: ''
                })
              } else {
                storageInstance.ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
                  this.$set(this.Data[type], this.ChooseImageIndex, {URL, Alt: ''})
                })
              }
              break
            case 'object':
              if (Array.isArray(this.MediaStoreConfig.ChooseImageList)) {
                this.MediaStoreConfig.ChooseImageList.forEach(imgPath => {
                  if (this.$store.state.user.permission.StaticDomain) {
                    this.Data[type].push({
                      URL: this.$store.state.user.permission.StaticDomain + imgPath,
                      Alt: ''
                    })
                  } else {
                    storageInstance.ref().child(imgPath).getDownloadURL().then((URL) => {
                      this.Data[type].push({
                        URL,
                        Alt: ''
                      })
                    })
                  }
                })
              }
              break
          }
          break
      }
    },
    SetSingleImage(type, index) {
      if (this.$store.state.user.permission.StaticDomain) {
        if (index) this.Data[type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
        else this.$set(this.Data, type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          if (index) this.Data[type][index] = URL
          else this.$set(this.Data, type, URL)
        })
      }
    },
    ResolveImageURL () {
      if (this.$store.state.user.permission.StaticDomain) {
        return Promise.resolve(this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      }
      return this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
        return URL
      })
    },
    OpenMediaMeta(type = 'Carousel', index = false) {
      if (index === false) {
        return false
      }
      this.MediaMetaConfig.Display = !this.MediaMetaConfig.Display
      this.MediaMetaConfig.ImageMeta = this.Data[type][index]
      this.ChooseImageType = type
      this.ChooseImageIndex = index
    },
    SetMeta() {
      this.MediaMetaConfig.ImageMeta = {}
      this.ChooseImageType = ''
      this.ChooseImageIndex = false
      this.MediaMetaConfig.Display = false
    },
    OpenVideoStore(type, index) {
      this.VideoStoreConfig.ChooseVideo = {}
      this.VideoStoreConfig.Display = !this.MediaStoreConfig.Display
      this.ChooseVideoIndex = index
      this.SelectType = type
    },
    SetVideo(type) {
      switch(type) {
        case 'ChapterPreview':
          this.PaidContent.ChapterList[this.ChooseVideoIndex].Preview = this.VideoStoreConfig.ChooseVideo
          break
        case 'ChapterList':
          this.PaidContent.ChapterList[this.ChooseVideoIndex].VideoData = this.VideoStoreConfig.ChooseVideo
          break
        case 'MainContent':
          this.PaidContent.MainContent.VideoData = this.VideoStoreConfig.ChooseVideo
          break
        case 'Preview':
          this.Data.Variable.Preview = this.VideoStoreConfig.ChooseVideo
          break
      }
    },
    RemoveImage(type = '', index) {
      this.Data[type].splice(index, 1)
    },
    OpenProductListModel() {
      this.ProductListModel = true
      this.ProductListModelComponent = () => import('@/components/product/ProductList')
    },
    ChooseProduct(items) {
      this.Data.Variable.SpecifyProductData = items
      this.Data.Variable.SpecifyProduct = items.map((data) => data.SeriesNum)
    },
    RemoveChooseProduct(index) {
      this.Data.Variable.SpecifyProductData.splice(index, 1)
      this.Data.Variable.SpecifyProduct.splice(index, 1)
    },
    ResetPreview() {
      this.Data.Variable.Preview = {
        MediaTitle: '',
        URL: '',
        Duration: '',
        Cover: this.$store.state.notFoundImage
      }
    },
    ResetMainContent() {
      this.PaidContent.MainContent = {
        Name: 'Default Chapter Name',
        Introduction: '',
        Content: '',
        VideoData: {
          MediaTitle: '',
          URL: '',
          Duration: '',
          Cover: this.$store.state.notFoundImage
        }
      }
    }
  }
}
</script>
